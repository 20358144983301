import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Privacy = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Privacy Policy')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Privacy Policy</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Privacy Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <div>
            <p>At {Application.ORG_DISPLAY_NAME}.net, we take your privacy seriously and are committed to safeguarding your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us when using our travel agency website and airline services in the USA. By accessing or using {Application.ORG_DISPLAY_NAME}.net, you consent to the practices described in this</p>
<h3>Information Collection and Use:</h3>
<ol> 
<li><strong> Personal Information:</strong></li>
</ol> 
<p>When you use our website to book flights or avail of our services, we may collect certain personal information, such as your name, email address, phone number, billing address, and payment details. We use this information to process your bookings, communicate with you, and provide you with the best possible customer service.</p>
<ol>
<li><strong> Non-Personal Information:</strong></li>
</ol>
<p>We may also collect non-personal information, such as your IP address, browser type, device information, and website usage data. This information is used to analyze trends, improve our website's functionality, and enhance your overall user experience.</p>
<h3>Cookies and Similar Technologies:</h3>
<p>{Application.ORG_DISPLAY_NAME}.net uses cookies and similar technologies to enhance your browsing experience. Cookies are small files stored on your device that help us remember your preferences and track your interactions with our website. You can modify your browser settings to disable cookies, but please note that this may affect certain features and functionality of our website.</p>
<h3>Third-Party Services:</h3>
<p>To provide you with a comprehensive travel experience, we may collaborate with third-party service providers, such as airlines, hotels, and payment processors. These providers may collect and process your personal information in accordance with their respective privacy policies. We recommend reviewing the privacy policies of these third parties before engaging in any transactions with them.</p>
<h3>Data Security:</h3>
<p>We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please understand that no method of transmission over the internet or electronic storage is 100% secure. While we strive to protect your data, we cannot guarantee absolute security.</p>
<h3>Data Retention:</h3>
<p>We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations.</p>
<h3>Children's Privacy:</h3>
<p>{Application.ORG_DISPLAY_NAME}.net is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from minors. If you are a parent or guardian and believe that your child has provided us with their personal information, please contact us immediately, and we will take appropriate measures to remove the information from our records.</p>
<h3>Updates to the Privacy Policy:</h3>
<p>We may update this Privacy Policy from time to time to reflect changes in our practices or for legal, operational, or regulatory reasons. We will notify you of any material changes through prominent notices on our website or via email.</p>
<h3>Contact Us:</h3>
<p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at [email address] or through our customer support.</p>
<p>By using {Application.ORG_DISPLAY_NAME}.net, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your information as described herein.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
