import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { composePageTitle } from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import { PageUrl } from './Constants';

const CancelRefund = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Cancellation & Refund Policy')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Cancellation & Refund Policy</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Cancellation & Refund Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
        <div className="bg-white shadow-md rounded p-4">
          
          <p>At {Application.ORG_DISPLAY_NAME}.net, we understand that plans may change, and sometimes cancellations are unavoidable. This Cancellation &amp; Refund Policy outlines the guidelines for canceling flight bookings and requesting refunds through our travel agency website. By making a booking with {Application.ORG_DISPLAY_NAME}.net, you agree to abide by this policy.</p>
<h2>Flight Cancellation:</h2>
<ol>
<li><strong> Cancellation Window: </strong>Flight cancellations can be requested up to [X hours/days] before the scheduled departure time, subject to the specific terms and conditions of the airline and fare type. Please note that certain fares may have restrictions on cancellations or may be non-refundable.</li>
<li><strong> Cancellation Process: </strong>To cancel your flight booking, log in to your {Application.ORG_DISPLAY_NAME}.net account and navigate to the "Manage Booking" section. Follow the instructions to initiate the cancellation process. You may also contact our customer support for assistance in canceling your booking.</li>
<li><strong> Cancellation Fees: </strong>The cancellation of flight bookings may incur fees imposed by the airline and {Application.ORG_DISPLAY_NAME}.net. These fees vary depending on the fare rules and the time of cancellation. You will be informed of any applicable charges before confirming your cancellation.</li>
<li><strong> Non-Refundable Fares: </strong>Some fares, especially promotional or discounted fares, may be non-refundable in the event of cancellation. In such cases, you will not be eligible for a refund, but you may still be able to make changes to your booking, subject to the airline's policies.</li>
</ol>
<h2>Refund Policy:</h2>
<ol>
<li><strong> Refund Eligibility: </strong>If you are eligible for a refund based on the fare rules and the time of cancellation, {Application.ORG_DISPLAY_NAME}.net will process the refund on your behalf, excluding any applicable cancellation fees.</li>
<li><strong> Refund Processing Time: </strong>Refunds are typically processed within [X business days/weeks] from the date of the cancellation request. However, please note that it may take additional time for the refund to reflect in your original payment method, depending on the payment processor and your financial institution.</li>
<li><strong> Refund Method: </strong>Refunds will be credited back to the original payment method used for the booking. If the original payment method is no longer valid, please contact our customer support for further assistance.</li>
<li><strong> Partial Refunds:</strong> In certain cases, a partial refund may apply, especially when a booking includes multiple passengers or services with different refund policies. The applicable refund amount will be communicated to you during the cancellation process.</li>
</ol>
<h2>Flight Changes and Rebooking:</h2>
<p>If you wish to modify your booking or rebook a different flight, please refer to our "Manage Booking" section on the website or contact our customer support. Changes to bookings may be subject to fees and fare differences.</p>
<h2>Force Majeure Events:</h2>
<p>{Application.ORG_DISPLAY_NAME}.net shall not be responsible for refunds in cases of flight cancellations or disruptions caused by force majeure events, including but not limited to natural disasters, political unrest, airline strikes, or government-imposed travel restrictions.</p>
<p>By using {Application.ORG_DISPLAY_NAME}.net and making flight bookings through our platform, you acknowledge that you have read and understood this Cancellation &amp; Refund Policy and agree to its terms and conditions.</p>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CancelRefund;
