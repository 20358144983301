const Application = {
  ORG: 'FlightsFare',
  ORG_DISPLAY_NAME: 'FlightsFare',
  ORG_ADD_LINE1: '1806 Hamilton St, Big Spring,',
  ORG_ADD_LINE2: 'TX 79720, USA',
  STORAGE_HOST: 'https://storage.googleapis.com',
  PRODUCTION_API_BASE_URL: 'https://myfaresinfo.com/alpha',
  LOCAL_API_BASE_URL: 'http://localhost:8080/alpha',
  STORAGE_URL_STATIC_ASSETS_PATH: 'https://storage.googleapis.com/sfc-static',
  DOMAIN: 'flightsfare.net',
  SUPPORT_PHONE: '+1 (844) 672-0906',
  CONTACT_EMAIL: 'teamflightfare@gmail.com',
  MAP_ADDRESS: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3375.7920317500366!2d-101.48761812396094!3d32.20983411275488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86f9228952ca2693%3A0xec14d2d5bce9d302!2s1806%20Hamilton%20St%2C%20Big%20Spring%2C%20TX%2079720%2C%20USA!5e0!3m2!1sen!2sin!4v1692026408756!5m2!1sen!2sin',
  CALL_NOW_POPUP_HEADING_TEXT: 'Call now for Booking, Changes and Cancellation',
  CALL_NOW_POPUP_SAVE_PRICE: 30,
  CALL_NOW_POPUP_SAME_PAGE_TIMEOUT_SEC: 30,
  Discount_Code: 'Fly30',
  DISCLAIMER: `We are an independent Travel service provider that provides affordable travel services. Services may be available from the brand owner. We do not claim that we are Airlines or are associated with Airlines in any way, all branding is purely for demonstrative purposes only and does not signify any association with any Airlines or organization. We are partners with Skybird, Picasso and GTT Tours & Travels, a certified IATA, IATAN, ARC.`,
  GA_ID: '',
};
export default Application;
