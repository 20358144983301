import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Terms = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Terms and Conditions')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Terms and Conditions</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Terms and Conditions</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
          <div> 
              <p>Welcome to {Application.ORG_DISPLAY_NAME}.net! These Terms & Conditions govern your use of our travel agency website and the services we provide in connection with airline services in the USA.</p>
              <h3>Booking and Service Terms:</h3>
              <ol>
              <li><strong> Eligibility: </strong>You must be at least 18 years old to use our website and services. By making a booking or using our services, you represent and warrant that you are of legal age to enter into a binding contract.</li>
              <li><strong> Booking Confirmation: </strong>All flight bookings and reservations made through {Application.ORG_DISPLAY_NAME}.net are subject to availability. The confirmation of your booking is dependent on the airline's acceptance of your reservation. We will provide you with booking details and an e-ticket once your booking is confirmed.</li>
              <li><strong> Travel Documents:</strong> It is your responsibility to ensure that you possess valid travel documents, including passports, visas, and any other required documentation, before commencing your journey. {Application.ORG_DISPLAY_NAME}.net will not be liable for any issues arising from inadequate or expired travel documents.</li>
              <li><strong> Payment: </strong>Payment for flight bookings must be made in full at the time of booking. We accept various payment methods, and all transactions are processed securely. Prices displayed on our website are in USD unless otherwise specified.</li>
              <li><strong> Cancellation and Refunds: </strong>Flight cancellation and refund policies vary based on the airline and fare type. Please review the specific terms and conditions applicable to your booking. Any cancellation or refund requests should be directed to our customer support, and processing fees may apply.</li>
              </ol>
              <h3>Website Use and Content:</h3>
              <ol>
              <li><strong> Intellectual Property: </strong>The content, design, and layout of {Application.ORG_DISPLAY_NAME}.net are protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, modify, distribute, or create derivative works based on any part of our website without our explicit written consent.</li>
              <li><strong> User Conduct:</strong> While using {Application.ORG_DISPLAY_NAME}.net, you must comply with all applicable laws and regulations. You shall not engage in any activities that may harm our website, interfere with its functionality, or compromise the security and privacy of other users.</li>
              <li><strong> Accuracy of Information: </strong>We strive to provide accurate and up-to-date information on our website. However, we do not guarantee the accuracy, completeness, or reliability of any content or information presented. Use the information on our website at your own risk.</li>
              </ol>
              <h3>Limitation of Liability:</h3>
              <ol>
              <li>{Application.ORG_DISPLAY_NAME}.net is a travel agency facilitating flight bookings and other travel-related services. We shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from your use of our website or services.</li>
              <li>While we make efforts to ensure the accuracy of flight schedules, prices, and other travel-related information, we are not responsible for any errors, omissions, or changes made by the airlines or third-party providers.</li>
              <li>{Application.ORG_DISPLAY_NAME}.net shall not be liable for any losses or damages caused by events beyond our control, including but not limited to airline strikes, natural disasters, or government regulations.</li>
              </ol>
              <h3>Indemnification:</h3>
              <p>You agree to indemnify and hold harmless {Application.ORG_DISPLAY_NAME}.net, its affiliates, officers, directors, employees, and agents from any claims, liabilities, losses, damages, costs, and expenses, including attorney fees, arising from your use of our website, services, or violation of these Terms & Conditions.</p>
              <h3>Modification of Terms:</h3>
              <p>We reserve the right to modify these Terms & Conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website. It is your responsibility to review these Terms regularly to stay informed about any updates.</p>
              <h3>Termination:</h3>
              <p>{Application.ORG_DISPLAY_NAME}.net may, at its sole discretion, terminate or suspend your access to the website and services at any time, without notice, for any reason, including but not limited to violation of these Terms.</p>
              <p>By using {Application.ORG_DISPLAY_NAME}.net, you acknowledge that you have read and understood these Terms & Conditions and agree to be bound by them.</p>


                        </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
