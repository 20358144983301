import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const About = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('About Us')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>About Us</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-6">Know More About Us</h2>
            <p>Welcome to {Application.ORG_DISPLAY_NAME}.net, your premier travel agency website for all your airline service needs in the USA. At {Application.ORG_DISPLAY_NAME}.net, we are committed to providing you with the best travel experience, whether you're planning a business trip, a family vacation, or a weekend getaway.</p>
            <h3>Why Choose {Application.ORG_DISPLAY_NAME}.net?</h3>
<p>Extensive Airlines Network: We have established strong partnerships with major airlines operating in the USA, offering you a wide selection of flights to various destinations. Whether you're flying domestically or internationally, we've got you covered.</p>
<ul>
<li><strong>Easy Booking Process: </strong>Our user-friendly website allows you to search, compare, and book flights effortlessly. With just a few clicks, you can find the best deals and secure your tickets, making the whole booking process quick and stress-free.</li>
</ul>
<ul>
<li><strong>Competitive Prices: </strong>At {Application.ORG_DISPLAY_NAME}.net, we understand the importance of budget-friendly travel. We consistently strive to offer competitive prices and exclusive discounts, ensuring that you get the best value for your money.</li>
</ul>
<ul>
<li><strong>Dedicated Customer Support: </strong>Our experienced customer support team is available 24/7 to assist you with any inquiries or concerns you may have. We are dedicated to providing you with exceptional service and ensuring that your travel experience is seamless from start to finish.</li>
</ul>
<ul>
<li><strong>Travel Inspiration:</strong> Looking for travel inspiration? Explore our travel blog, which is regularly updated with exciting travel tips, destination guides, and insider insights to help you plan your next adventure.</li>
</ul>
<ul>
<li><strong>Customized Travel Solutions:</strong> We understand that every traveler is unique, and that's why we offer personalized travel solutions tailored to your specific preferences and requirements.</li>
</ul>
<h3>Experience the Convenience of Online Travel Booking:</h3>
<p>With {Application.ORG_DISPLAY_NAME}.net, you have the power to plan your trip at your fingertips. Say goodbye to long queues and time-consuming processes; instead, embrace the ease and convenience of online travel booking with us.</p>
<p>So, whether you're embarking on a solo journey, a family vacation, or a group trip, let {Application.ORG_DISPLAY_NAME}.net be your trusted partner in making your travel dreams come true. Join us today and discover a world of possibilities with our top-notch airline services in the USA.</p>
            <div className="row mt-4 mb-2">
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-thumbs-up"></i>
                  </div>
                  <h3>Best Deal Offer</h3>
                  <p>
                    Find our lowest deal to destinations worldwide, guaranteed
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-paper-plane"></i>{' '}
                  </div>
                  <h3>Easy Booking</h3>
                  <p>
                    Search, select and save - the fastest way to book your trip
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-eye"></i>{' '}
                  </div>
                  <h3>24/7 Customer Care</h3>
                  <p>
                    Get award-winning service and special deals by calling{' '}
                    {Application.SUPPORT_PHONE}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
